<template>
  <div class="env-component-container">
    <router-view/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageEnvComponent',
  breadcrumb () {
    const { projectCanonical, projectName, envCanonical } = this
    const component = this.envComponent(envCanonical, this.componentCanonical)
    const componentName = component?.name || this.componentCanonical

    return constructBreadcrumb(this.$options.name, componentName, [
      {
        label: this.$t('Components'),
        name: 'environmentOverview',
        params: { projectCanonical, envCanonical },
      },
      {
        label: envCanonical,
        name: 'environment',
        params: { projectCanonical, envCanonical },
      },
      {
        label: this.$t('Environments'),
        name: 'projectOverview',
        params: { projectCanonical },
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  props: {
    componentCanonical: {
      type: String,
      required: true,
    },
    envCanonical: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('organization/project', [
      'envComponent',
    ]),
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.environmentComponent',
      },
      es: {
        title: '@:routes.environmentComponent',
      },
      fr: {
        title: '@:routes.environmentComponent',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .env-component-container {
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    height: 100%;
  }
</style>
